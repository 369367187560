import React, { useEffect, useState, useRef } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvField, AvForm } from "availity-reactstrap-validation"
import close from "../../assets/images/close.svg"
import ReactQuill from "react-quill"

const QuickReplies = () => {
  useEffect(() => {
    getQuickReplies()
    getNegativeKeywords()
  }, [])

  const [dataArr, setDataArr] = useState({
    message: "",
    messageCursorPosition: 0,
  }) // for message data
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [modal, setModal] = useState({ isOpen: false }) // for add and edit modal

  const quillRef = useRef(null)

  function findAllIndices(text, nw) {
    const indices = []
    const regex = new RegExp(`\\b${nw}\\b`, "gi") // 'gi' makes it case-insensitive
    let match

    // Use regex to find all exact matches of the word
    while ((match = regex.exec(text)) !== null) {
      indices.push(match.index)
    }

    return indices
  }
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on("text-change", function (delta, oldDelta, source) {
        if (source === "user") {
          const text = editor.getText()
          editor.formatText(0, text.length, {
            color: "black",
            background: false,
          })

          // For merge field
          let regex = /\{\{[^{}]+\}\}|\[@[^\[\]]+\]/g
          let match
          // Apply formatting to matched patterns
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#5932EA",
              // background: "#E6F3FF",
            })
          }
          // For text spinner
          regex = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#008000",
              // background: "#E6F3FF",
            })
          }
          negativeKeywords.forEach(nw => {
            const indexs = findAllIndices(text.toLowerCase(), nw.toLowerCase())
            if (indexs.length) {
              indexs.forEach(index => {
                editor.formatText(index, nw.length, {
                  color: "#D80415",
                  // background: "#E6F3FF",
                })
              })
            }
          })
        }
      })
    } else {
      setTimeout(() => {
        setReloadKey(Math.random())
      }, 100)
    }
  }, [negativeKeywords, reloadKey])

  const customModules = {
    toolbar: false,
  }
  const customFormats = ["bold", "italic", "color"]

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const messageTextAreaRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false) // for circular spinner
  const [tableReloadKey, setTableReloadKey] = useState(Math.random()) // for table reload key
  const [quickReplies, setQuickReplies] = useState([]) // for listing of quick reply
  const [deleteQuickReply, setDeleteQuickReply] = useState({ isOpen: false }) // for delete quick reply
  const [selectedMessageIndex, setSelectedMessageIndex] = useState() // for selected message in avfield
  const [merge, setMerge] = useState({ isOpen: false }) // for merge field toggle
  const [showNegativeKeyWord, setShowNegativeKeyWord] = useState(false)

  const getQuickReplies = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/pullQRS",
        config
      )

      if (response?.data) {
        setQuickReplies(response?.data?.data)
        setIsLoading(false)
        setTableReloadKey(Math.random())
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const handleDeleteQuickReply = async id => {
    setIsLoading(true)
    const data = {}
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/template/deleteQR/${id}`,
        data,
        config
      )
      if (response) notify("success", "Quick Reply Deleted Successfully")
      getQuickReplies()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.error)
    }
  }

  const quickRepliesColumn = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      cell: row => row?.title,
      minWidth: "100px",
      maxWidth: "250px",
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
      cell: row => row?.message,
      minWidth: "620px",
    },
    {
      name: "Edit",
      selector: row => row.edit,
      sortable: true,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              openModalHandler("updateQuickReply", row)
              setDataArr({
                message: row?.message,
                messageCursorPosition: row?.message?.length,
              })
            }}
            color={"outline-primary"}
            className={"has-icon me-2"}
          >
            <i className="fas fa-edit"></i>
          </Button>
        </div>
      ),
      minWidth: "80px",
      maxWidth: "80px",
    },
    {
      name: "Delete",
      selector: row => row._id,
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              setDeleteQuickReply({ isOpen: true, data: row })
            }}
            color={"outline-danger"}
            className={"has-icon"}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "80px",
      maxWidth: "80px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "AddQuickReply": {
        setModal({
          isOpen: true,
          modalTitle: "Add Quick Reply",
          confirmText: "Save",
          action,
        })
        break
      }
      case "updateQuickReply": {
        setModal({
          isOpen: true,
          modalTitle: "Update Quick Reply",
          confirmText: "Update",
          ...data,
          action,
        })
        break
      }

      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    switch (modal.action) {
      case "AddQuickReply": {
        const data = { ...value, message: getCleanMessage(dataArr.message) }
        handleAddQuickReply(data)
        break
      }
      case "updateQuickReply": {
        const data = { ...value, message: getCleanMessage(dataArr.message) }
        handleEditQuickReply(data, modal?._id)
        break
      }
    }
  }

  const handleAddQuickReply = async value => {
    setIsLoading(true)
    const data = {
      title: value?.title,
      message: value?.message,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/template/createQR/",
        data,
        config
      )
      if (response) notify("success", "Quick Reply Created Successfully")
      setIsLoading(false)
      getQuickReplies()
      setDataArr({
        message: "",
        messageCursorPosition: 0,
      })
      setModal({ isOpen: false })
      setMerge({ isOpen: false })
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }
  const handleEditQuickReply = async (value, quickReplyId) => {
    setIsLoading(true)
    const data = {
      title: value?.title,
      message: value?.message,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/template/updateQR/${quickReplyId}`,
        data,
        config
      )
      if (response) notify("success", "Quick reply updated Successfully")
      setIsLoading(false)

      getQuickReplies()
      setDataArr({
        message: "",
        messageCursorPosition: 0,
      })
      setModal({ isOpen: false })
      setMerge({ isOpen: false })
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  const handleSelectedChange = e => {
    const textArea = e.target
    const selectionStartIndex = textArea.selectionStart
    const selectionEndIndex = textArea.selectionEnd
    if (selectionStartIndex || selectionEndIndex) {
      setSelectedMessageIndex({
        start: selectionStartIndex,
        end: selectionEndIndex,
      })
    }
  }

  const tags = [
    "[@firstName]",
    "[@lastName]",
    "[@street]",
    "[@city]",
    "[@state]",
    "[@zip]",
    "[@companyName]",
    "[@repNameAlias]",
  ]

  function updateMessageCursorPosition(position) {
    let tempArr = dataArr
    tempArr.messageCursorPosition = position
    setDataArr(tempArr)
  }

  // function addTag(tag) {
  //   let tempArr = dataArr
  //   if (selectedMessageIndex?.start || selectedMessageIndex?.end) {
  //     const start = selectedMessageIndex.start
  //     const end = selectedMessageIndex.end
  //     tempArr.message =
  //       tempArr.message.substring(0, start) +
  //       tag +
  //       tempArr.message.substring(end)

  //     setSelectedMessageIndex()
  //     updateMessageCursorPosition(start + tag.length)
  //   } else {
  //     tempArr.message =
  //       tempArr.message?.slice(0, tempArr.messageCursorPosition) +
  //       tag +
  //       tempArr.message?.slice(tempArr.messageCursorPosition)
  //     updateMessageCursorPosition(tempArr.messageCursorPosition + tag.length)
  //   }

  //   setDataArr(tempArr)
  // }
  function getMessageCharCount(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    let mssg = tempElement.innerText
    const textSpinners = []
    const mssgWithoutTextSpinner = mssg.replace(
      /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g,
      match => {
        textSpinners.push(match)
        return ""
      }
    )

    return mssgWithoutTextSpinner?.length
  }

  function addTag(tag) {
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection()
    const position = range ? range.index : getMessageCharCount(dataArr.message)
    const positionEnd = range ? range.length : null
    if (!isNaN(position) && !isNaN(positionEnd)) {
      editor.deleteText(position, positionEnd)
    }
    editor.insertText(position, tag, "user")
    editor.setSelection(position + tag.length)
    setReloadKey(Math.random())
  }

  function handleDataChange(key, val) {
    let tempArr = dataArr
    tempArr[key] = val
    setDataArr(tempArr)
    if (!quillRef.current) {
      setReloadKey(Math.random())
    }
  }

  const getNegativeKeywords = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/listNegativeKeywords/",
        config
      )
      if (response.data) {
        setNegativeKeywords(response.data.data)
      }
    } catch (error) {
      notify("error", error.response?.data?.message)
    }
  }

  function getCleanMessage(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    return tempElement.innerText
  }
  function isPresent(word, string) {
    const val = getCleanMessage(string)
    const lowerStr = val.toLowerCase()
    const lowerWord = word.toLowerCase()
    const pattern = new RegExp(`\\b${lowerWord}\\b`)
    return pattern.test(lowerStr)
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Phone Numbers | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {quickReplies?.length ? `${quickReplies?.length}` : null}{" "}
                    Quick Replies
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Quick Replies</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    <button
                      className="main-btn"
                      onClick={() => {
                        openModalHandler("AddQuickReply")
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span> Add Quick Reply</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              key={tableReloadKey}
              columns={quickRepliesColumn}
              data={quickReplies}
              fixedHeader
              className="c-table"
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationTotalRows={quickReplies?.length}
              keyField={"id"}
              customStyles={customStyles}
            />
          </div>
        </Container>
      </div>

      {/* Add and edit Quick reply modal  */}
      <Modal
        isOpen={modal?.isOpen}
        toggle={() => {
          setModal({ isOpen: !modal?.isOpen })
          setMerge({ isOpen: false })
          setShowNegativeKeyWord(false)
        }}
        className="c-modal"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">{modal?.modalTitle}</h5>
          <button
            type="button"
            onClick={() => {
              setModal({ isOpen: !modal?.isOpen })
              setShowNegativeKeyWord(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleModalSubmit(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <AvField
                      name="title"
                      placeholder="Title"
                      type="text"
                      errorMessage="Enter Title"
                      className="form-control"
                      value={modal?.title ? modal?.title : ""}
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid title",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    {/* <AvField
                      name="message"
                      placeholder="Message"
                      type="textarea"
                      ref={messageTextAreaRef}
                      errorMessage="Enter Message"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        maxLength: { value: 320 },
                      }}
                      id="validationCustom02"
                      value={dataArr.message}
                      rows={9}
                      onChange={e => {
                        handleDataChange("message", e.target.value)
                        updateMessageCursorPosition(e.target.selectionStart)
                      }}
                      onClick={e => {
                        updateMessageCursorPosition(e.target.selectionStart)
                      }}
                      onSelect={e => {
                        handleSelectedChange(e)
                      }}
                    /> */}

                    <ReactQuill
                      placeholder="Write message..."
                      ref={quillRef}
                      value={dataArr.message}
                      className="message-text-editor"
                      onChange={value => {
                        handleDataChange("message", value)
                      }}
                      modules={customModules}
                      formats={customFormats}
                      theme="snow"
                    />

                    <span style={{ float: "right", fontSize: "12px" }}>
                      {dataArr?.message?.length <= 160 ? (
                        <i
                          className="fas fa-check-circle fa-sm me-1"
                          style={{ color: "Green" }}
                        ></i>
                      ) : null}
                      {dataArr?.message?.length <= 320 &&
                      dataArr?.message?.length > 160 ? (
                        <i
                          className="fas fa-exclamation-circle fa-sm me-1"
                          style={{ color: "orange" }}
                        ></i>
                      ) : null}
                      {dataArr?.message?.length > 320 ? (
                        <i
                          className="fas fa-times-circle fa-sm me-1"
                          style={{ color: "red" }}
                        ></i>
                      ) : null}
                      {dataArr?.message?.length ? dataArr?.message?.length : 0}
                      /320
                    </span>
                  </FormGroup>

                  <div className="d-aut-ac-wraps ">
                    <Dropdown
                      isOpen={false}
                      toggle={() => {
                        setMerge({ isOpen: !merge.isOpen })
                      }}
                    >
                      <DropdownToggle
                        className="btn "
                        caret
                        color={"outline-primary"}
                      >
                        Merge Fields
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>

                      <DropdownMenu
                        className={
                          merge.isOpen ? "d-flex flex-column gap-1 p-2" : ""
                        }
                        // style={{height : '120px' , overflow:'auto'}}
                      >
                        {tags?.length > 0 &&
                          tags.map((item, tagIndex) => (
                            <DropdownItem
                              key={tagIndex}
                              type={"button"}
                              className="drp-dwn-item"
                              onClick={() => {
                                addTag(item)
                              }}
                            >
                              {item}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                    <button
                      onClick={e => {
                        setShowNegativeKeyWord(!showNegativeKeyWord)
                        e.preventDefault()
                      }}
                    >
                      {showNegativeKeyWord ? (
                        <i className="fas fa-eye-slash color-primary"></i>
                      ) : (
                        <i className="fas fa-eye color-primary"></i>
                      )}
                      <span>
                        {showNegativeKeyWord ? "Hide" : "Show"} negative
                        keywords
                      </span>
                    </button>
                  </div>
                  {showNegativeKeyWord ? (
                    <table className="d-flex flex-wrap p-0 ">
                      <tbody>
                        <tr className="d-flex flex-wrap py-3 gap-2">
                          <th>Negative Keywords:</th>
                          {negativeKeywords.map((nk, nkIndex) => (
                            <td
                              className={`px-1 bg-light rounded-1 ${
                                isPresent(nk, dataArr?.message)
                                  ? "badge-soft-danger"
                                  : "bg-light "
                              } `}
                              value={nkIndex}
                              key={nkIndex}
                            >
                              {nk}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  ) : null}
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setModal({ isOpen: false })
                    setDataArr({
                      message: "",
                      messageCursorPosition: 0,
                    })
                    setMerge({ isOpen: false })
                    setShowNegativeKeyWord(false)
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {/* Delete lead Stage sweetAlert  */}

      {deleteQuickReply?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteQuickReply(deleteQuickReply?.data?._id)
            setDeleteQuickReply({ isOpen: false })
          }}
          onCancel={() => {
            setDeleteQuickReply({ isOpen: false })
          }}
        >
          You won't be able to revert{" "}
          <strong>{deleteQuickReply?.data?.title}</strong>
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default QuickReplies
